import "bootstrap/dist/css/bootstrap.min.css";
import MyNavBar from "./components/MyNavBar";
import Portfolio from "./components/Portfolio.js";
import HeaderImage from "./components/HeaderImage.js";
import About from "./components/About";
import Experience from "./components/Experience";
import Connect from "./components/Connect";
import Recommendations from "./components/Recommendations";
import Education from "./components/Education";
import Technologies from "./components/Technologies";

function App() {

  return (
    <div className="wrapper">
      <div className="background-container">
        <div className="background-hue">
          <div id="background-hue-left"></div>
          <div id="background-hue-right"></div>
        </div>
        <img
          id="human-hand"
          className="background-image"
          src={require("./images/humanhand.png")}
          alt=""
        />
        <img
          id="robot-hand"
          className="background-image"
          src={require("./images/robothand.png")}
          alt=""
        />
      </div>
      <div className="foreground-container">
        <img
          id="veins"
          className="foreground-image"
          src={require("./images/veins.png")}
          alt=""
        />
        <img
          id="circuit"
          className="foreground-image"
          src={require("./images/mycircuit.png")}
          alt=""
        />
      </div>
      <div className="app-container">
        <HeaderImage />
        <MyNavBar />
        <About />
        <Technologies />
        <Portfolio />
        <Experience />
        <Education />
        <Recommendations />
        <Connect />
      </div>

      <div className="opening-panel" id="left-panel"></div>
      <div className="opening-panel" id="right-panel"></div>
      <div className="opening-panel" id="jb">
        JB
      </div>
    </div>
  );
}

export default App;
