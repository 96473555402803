import React from "react";
import { Row, Col } from "react-bootstrap";

function Technologies() {
  return (
    <div id="technology" className="content-component text-details">
      <div className="m-2 my-header-text">Technologies</div>

      <Row md={12}>
        <Col md={2}>
          <img
            className="m-2"
            src={require("../images/frontEnd.png")}
            alt=""
            height={100}
            width={100}
          />
        </Col>
        {/* Key systems or tech */}
        <Col md={6}>
          <Row>
            <div className="m-2 text-to-highlight">Front End</div>
          </Row>
          <Row>
            <p className="ms-4">
              HTML, CSS, JavaScript, React, Bootstrap, JQuery, CSS annimations
            </p>
          </Row>
        </Col>
      </Row>

      <Row md={12}>
        <Col md={2}>
          <img
            className="m-2"
            src={require("../images/backEnd.png")}
            alt=""
            height={100}
            width={100}
          />
        </Col>
        {/* Key systems or tech */}
        <Col md={6}>
          <Row>
            <div className="m-2 text-to-highlight">Back End</div>
          </Row>
          <Row>
            {" "}
            <p className="ms-4">
              Node.js, Express, MySQL, MongoDB, RESTful APIs, Strapi, GraphQL
            </p>
          </Row>
        </Col>
      </Row>

      <Row md={12}>
        <Col md={2}>
          <img
            className="m-2"
            src={require("../images/devOps.png")}
            alt=""
            height={100}
            width={100}
          />
        </Col>
        {/* Key systems or tech */}
        <Col md={6}>
          <Row>
            <div className="m-2 text-to-highlight">Development and Dev Ops</div>
          </Row>
          <Row>
            <p className="ms-4">
              GitHub, Heroku, Postman, FIrebase, AWS, Docker, Auth0
            </p>
          </Row>
        </Col>
      </Row>
      <Row md={12}>
        <Col md={2}>
          <img
            className="m-2"
            src={require("../images/comingSoon.png")}
            alt=""
            height={100}
            width={100}
          />
        </Col>
        {/* Key systems or tech */}
        <Col md={6}>
          <Row>
            <div className="m-2 text-to-highlight">Coming Soon...</div>
          </Row>
          <Row>
            <p className="ms-4">Python, Machine Learning, AI Implementation</p>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Technologies;
